import SEO from '../components/seo';
import Layout from '../components/layout';
import React from 'react';
import { graphql, Link } from 'gatsby';
import Markdown from 'react-markdown';
const init = {
  name: '',
  phone: '',
  mail: '',
  message: ''
};

function encode (data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function Contact (props) {
  const { data: { allMarkdownRemark: { edges: [x] }, site } } = props;
  const { node: { frontmatter: { text } } } = x;

  const [form, setForm] = React.useState(init);
  const [error, setErr] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const windowGlobal = typeof window !== 'undefined' && window;

  function handleChange ({ target: { value, name } }) {
    setForm({
      ...form,
      [name]: value
    });
  }

  function submit (e) {
    e.preventDefault();
    const formName = e.target.getAttribute('name');
    setLoading(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        ...form
      })
    })
      .then(() => {
        setErr(undefined);
        setLoading(false);
        setSubmitted(true);
        windowGlobal && window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
      .catch(error => {
        setErr(error);
        setLoading(false);
        setSubmitted(false);
        windowGlobal && window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
  }
  function renderLink (props) {
    return (<a href={props.href} target={'_blank'}>{props.children}</a>);
  }

  function renderParagraph (props) {
    return <p className={' has-text-left-mobile'}>{props.children}</p>;
  }

  return (
    <Layout title={site.siteMetadata.title}>
      <SEO title={'Franziska Schittler Kontakt'} />
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            <h1 className={'is-size-1'}>Kontakt</h1><br/>
            <div className={'content is-size-5 justify'}>
              <Markdown source={`${text}`} renderers={{ link: renderLink, paragraph: renderParagraph }}/>
            </div>
          </div>
        </div>
      </section>
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            {submitted &&
            <div className="content column is-12 is-centered is-vcentered">
              <article className="message is-success">
                <div className="message-header">
                  <p>Vielen Dank für Ihre Anfrage.</p>
                </div>
                <div className="message-body">
                    Ich habe Ihre Nachicht erhalten, Sie erhalten in kürze eine Antwort.
                </div>
              </article>
            </div>
            }
            {submitted && <Link to={'/'}><button className={'button'}>Home</button></Link>}
            {!submitted &&
            <form
              name="contact-franziska"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={submit}
            >
              <input type="hidden" name="form-name" value="booking" disabled={true}/>
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={handleChange}/>
                </label>
              </div>
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input className="input" type="text" name={'name'} placeholder="Vorname Nachname" value={form.name} onChange={handleChange} />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left">
                  <input className="input" type="email" name={'mail'} placeholder="beispiel@gmail.com" value={form.mail}
                    onChange={handleChange}/>
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"/>
                  </span>
                </div>
              </div>
              <div className="field">
                <label className="label">Telefonnummer</label>
                <div className="control">
                  <input className="input" type="text" name={'phone'} placeholder="Handy oder Festnetz" value={form.phone}
                    onChange={handleChange}/>
                </div>
              </div>
              <div className="field">
                <label className="label">Nachicht</label>
                <div className="control">
                  <textarea className="textarea" placeholder="Hallo..." name={'message'} onChange={handleChange}
                    value={form.message}/>
                </div>
              </div>
              <br/>
              <button className="button is-large" type={'submit'}>Senden</button>
            </form>
            }
          </div>
        </div>
      </section>

    </Layout>
  );
}
export const pageQuery = graphql`
    query Conact {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "contact"}}}) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        text
                    }
                }
            }
        }
    }
`;
